import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import {
  AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Drawer,
  List, ListItem, ListItemIcon, ListItemText, CircularProgress, Snackbar,
  Divider
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../assets/images/black-logo.png';
import config from '../config';
import { has_access } from '../lib/access';
import AppsButton from '../components/AppsButton';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    maxHeight: 40,
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    color: '#222',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  logoLeft: {
    height: '100%',
    width: 'auto',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
    backgroundColor: 'inherit',
  },
  appName: {
    verticalAlign: 'middle',
    display: 'inline-block',
    paddingTop: '2px',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    paddingLeft: '15px',
    marginTop: '4px',
    color: '#555',
  },
  userName: {
    fontSize: '0.75em',
    paddingRight: '15px',
    [theme.breakpoints.down('sm')]: {
      display: 'None',
    },
  },
  userIcon: {
    fontSize: '30px',
    color: '#00b31f',
  },
  company: {
    fontSize: '18px',
    marginLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  version: {
    fontSize: '12px',
  }
}));

function Header(props) {
  let history = useHistory();
  const {user, access, isAuthenticated, company} = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoadingLogout, setIsLoadingLogout] = useState(null);
  const [openErrorLogout, setOpenErrorLogout] = useState(false);

  if (!isAuthenticated) {
    return (
      <Typography className={classes.title} variant="h6" noWrap>
        <img src={Logo} alt="Logo" className={classes.logo} />
        <Typography component="span" className={classes.appName}>
          Admin App
        </Typography>
      </Typography>
    );
  }

  const handleLogout = async () => {
    try {
      setIsLoadingLogout(true);
      await Auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Error al cerrar la sesión:', error);
      setIsLoadingLogout(false);
      setOpenErrorLogout(true);
    }
  };

  const handleCloseErrorLogout = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorLogout(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleShowProfile = () => {
    history.push('/profile');
    handleMenuClose();
  }

  const handleClickMenu = (url) => (event) => {
    history.push(url);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Drawer variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
      }}
      open={drawerOpen} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <Icons.ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {/*<ListItem>
            <Avatar className={classes.avatar}>
              <img src={LogoNK} alt="Logo" className={classes.logoLeft} />
            </Avatar>
          </ListItem>*/}
          <ListItem button onClick={handleClickMenu('/')}>
            <ListItemIcon><Icons.Home /></ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
          <ListItem button onClick={handleClickMenu('/services')}>
            <ListItemIcon><Icons.Apps /></ListItemIcon>
            <ListItemText primary="Servicios" />
          </ListItem>
          {
            has_access(access, 'user', ['readonly', 'admin']) &&
            <ListItem button onClick={handleClickMenu('/users')}>
              <ListItemIcon><Icons.Group /></ListItemIcon>
              <ListItemText primary="Usuarios" />
            </ListItem>
          }
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href={config.apps.support}>
            <ListItemIcon><Icons.Help /></ListItemIcon>
            <ListItemText primary="Soporte" />
          </ListItem>
          {
            has_access(access, 'billing', ['readonly', 'admin']) &&
            <ListItem button component="a" href={config.apps.billing}>
              <ListItemIcon><Icons.Payment /></ListItemIcon>
              <ListItemText primary="Facturación" />
            </ListItem>
          }
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={'Versión ' + config.version} primaryTypographyProps={{className: classes.version}} />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleShowProfile}>Mi perfil</MenuItem>
      <MenuItem onClick={handleLogout}>
        {
          isLoadingLogout ?
            <CircularProgress />
            :
            'Cerrar Sesión'
        }
      </MenuItem>
    </Menu>
  );

  const renderErrorLogout = (
    <Snackbar open={openErrorLogout} autoHideDuration={6000} onClose={handleCloseErrorLogout} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert onClose={handleCloseErrorLogout} severity="error">
        Ocurrió un error al cerrar la sesión, intenta nuevamente
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
          >
            <Icons.Menu />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <img src={Logo} alt="Logo" className={classes.logo} />
            <Typography component="span" className={classes.appName}>
              Admin App
            </Typography>
          </Typography>
          <AppsButton />
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Typography className={classes.userName} component="span" noWrap>
                {user.attributes.name} {user.attributes.family_name}
              </Typography>
              <Icons.AccountCircle className={classes.userIcon} />
            </IconButton>
            <Typography className={classes.company} component="span" noWrap>
              {company}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderProfileMenu}
      {renderErrorLogout}
    </>
  );
}

export default Header;