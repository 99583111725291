import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Paper, CircularProgress } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { invokeApig } from '../lib/awsLib';

const useStyles = makeStyles((theme) => ({
    section: {
        margin: theme.spacing(3, 0),
        marginBottom: theme.spacing(10),
        width: 'inherit',
    },
    paper: {
        padding: theme.spacing(2),
    },
    gridMaxMd: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.lg,
        },
    },
    serviceBox: {
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '15px',
        padding: '15px',
        transition: 'transform 0.5s',
        '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }
    },
    serviceIconContainer: {
        color: '#fff',
        backgroundColor: '#00b31f',
        borderRadius: '60px',
        width: '110px',
        height: '110px',
    },
    serviceIcon: {
        padding: '5px',
        minWidth: 0,
        margin: '15px',
        borderRadius: '5px',
        fontSize: 80,
    },
    serviceLink: {
        textDecoration: 'none'
    },
    servicesTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}));

function ServicePage(props) {
    const {access, isAutenticate} = props.params;
    const classes = useStyles();
    const [apps, setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getApps();
    }, []);

    const getApps = async () => {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/user/menu`,
            });
            if (response.code === 0) {
                setApps(response.apps);
            }
        } catch(e) {}
        setIsLoading(false);
    }

    const goToUrl = (url) => {
        window.location.href = url;
    }

    return (
        <Grid container spacing={2} className={classes.section} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Paper className={classes.paper}>
                    <Typography variant="h3" className={classes.servicesTitle}>Servicios disponibles</Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        {
                            isLoading ?
                            <CircularProgress />
                            :
                            (
                                (apps && apps.length > 0)
                                ?
                                apps.map((app, index) => {
                                    const AppIcon = Icons[app.icon];
                                    return (
                                        <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => goToUrl(app.url)} className={classes.serviceBox}>
                                                <div className={classes.serviceIconContainer}>
                                                    <AppIcon className={classes.serviceIcon}/>
                                                </div>
                                                <Typography variant="h6">{app.name}</Typography>
                                                <Typography variant="body2">{app.desc}</Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.serviceBox}>
                                        <Typography variant="h6">No tiene servicios disponibles</Typography>
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ServicePage;