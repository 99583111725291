import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, Avatar, Box, Paper, CircularProgress } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import { has_access } from '../lib/access';
import { invokeApig } from '../lib/awsLib';

const useStyles = makeStyles((theme) => ({
    welcome: {
        marginTop: '15px',
    },
    userCard: {
        backgroundColor: '#383838',
        color: '#fff',
        textAlign: 'center',
    },
    avatar: {
        backgroundColor: '#00b31f',
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: 'auto'
    },
    section: {
        margin: theme.spacing(3, 0),
        marginBottom: theme.spacing(10),
        width: 'inherit',
    },
    paper: {
        padding: theme.spacing(2),
    },
    gridMaxMd: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.lg,
        },
    },
    summary: {
        textAlign: 'center',
    },
    serviceBox: {
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '15px',
        padding: '15px',
        transition: 'transform 0.5s',
        '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }
    },
    serviceIconContainer: {
        color: '#fff',
        backgroundColor: '#00b31f',
        borderRadius: '60px',
        width: '110px',
        height: '110px',
    },
    serviceIcon: {
        padding: '5px',
        minWidth: 0,
        margin: '15px',
        borderRadius: '5px',
        fontSize: 80,
    },
    serviceLink: {
        textDecoration: 'none'
    },
    servicesTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}));

function HomePage(props) {
    const {access, isAutenticate} = props.params;
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState({});
    const [apps, setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        obtenerDatosUsuario();
        getApps();
    }, []);

    const obtenerDatosUsuario = async () => {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            setUserInfo(userData);
        } catch (error) {
            console.error('Error al obtener los datos del usuario: ', error);
        }
    }

    const getApps = async () => {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/user/menu`,
            });
            if (response.code === 0) {
                setApps(response.apps);
            }
        } catch(e) {}
        setIsLoading(false);
    }

    const goToUrl = (url) => {
        window.location.href = url;
    }

    return (
        <Grid container spacing={2} className={classes.section} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Card className={classes.userCard}>
                    <CardContent>
                        <Avatar className={classes.avatar}>
                            <Icons.AccountCircle />
                        </Avatar>
                        {
                            (userInfo && userInfo.attributes) &&
                            <>
                                <Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
                                <Typography variant="h6">{userInfo.attributes.email}</Typography>
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Card className={classes.summary}>
                    <CardContent>
                        <Typography variant="h4">Admin App</Typography>
                        <Typography>
                            Este es el punto de partida en las Apps de <b>Neokode</b>
                        </Typography>
                        <Typography>
                            Acá podrás acceder a las <b>Apps</b> que tienes disponible, acceder al <b>Soporte</b>
                            {has_access(access, 'user', ['readonly', 'admin']) && <>, configurar tus <b>usuarios</b></>}
                            {has_access(access, 'billing', ['readonly', 'admin']) && <>, ver y pagar las <b>facturas</b></>}.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Paper className={classes.paper}>
                    <Typography variant="h4" className={classes.servicesTitle}>Servicios disponibles</Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        {
                            isLoading ?
                            <CircularProgress />
                            :
                            (
                                (apps && apps.length > 0)
                                ?
                                apps.map((app, index) => {
                                    const AppIcon = Icons[app.icon];
                                    return (
                                        <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => goToUrl(app.url)} className={classes.serviceBox}>
                                                <div className={classes.serviceIconContainer}>
                                                    <AppIcon className={classes.serviceIcon}/>
                                                </div>
                                                <Typography variant="h6">{app.name}</Typography>
                                                <Typography variant="body2">{app.desc}</Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.serviceBox}>
                                        <Typography variant="h6">No tiene apps disponibles</Typography>
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
export default HomePage;